import React from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import classNames from "classnames";
import _map from "lodash/map";
import _includes from "lodash/includes";
import _toString from "lodash/toString";
import _get from "lodash/get";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import I18n from "../../../../../utils/i18n";

const CheckboxList = ({ elements, inputName, withPin = false, pinning, pinned, handlePin, counter }) => {
    const [, meta, helper] = useField(inputName);

    return _map(elements, (element) => (
        <React.Fragment key={`${inputName}-${element.id}`}>
            <div
                className={classNames("d-on-hover d-inline-flex align-items-center w-100", {
                    "font-weight-bold": withPin && _includes(pinned, _toString(element.id)),
                })}
            >
                <Form.Check custom>
                    <Form.Check.Input
                        className="multiselect-filter d-inline-block text-truncate"
                        name={inputName}
                        onChange={(event) => {
                            const newValue = { ...meta.value, [element.id]: event.target.checked };
                            helper.setValue(newValue);
                        }}
                        type="checkbox"
                        id={`${inputName}-${element.id}`}
                        value={element.id}
                        checked={meta.value[element.id] || false}
                    />
                    <Form.Check.Label htmlFor={`${inputName}-${element.id}`} className="w-100">
                        {I18n.t("companies.profiles.sections.filters.counter", {
                            name: element.name,
                            count: _get(counter, element.id) || 0,
                        })}
                    </Form.Check.Label>
                </Form.Check>
                {withPin &&
                    (pinning === element.id ? (
                        <FontAwesomeIcon icon={faCircleNotch} className="align-baseline ml-2 fa-spin" />
                    ) : (
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            className={classNames("align-baseline ml-2 cursor-pointer", {
                                "hover-hide": !_includes(pinned, _toString(element.id)),
                                "text-secondary": !_includes(pinned, _toString(element.id)),
                            })}
                            size="xs"
                            onClick={() => handlePin(inputName, element.id, !_includes(pinned, _toString(element.id)))}
                        />
                    ))}
            </div>
            {element.id === "other" && meta.value[element.id] && (
                <div className="mt-2 mb-2 d-on-hover w-100">
                    <Form.Control
                        type="text"
                        name={`${inputName}-clarification`}
                        onChange={(event) => {
                            const newValue = { ...meta.value, clarification: event.target.value };
                            helper.setValue(newValue);
                        }}
                        value={meta.value.clarification || ""}
                        id={`${inputName}-clarification`}
                        placeholder={I18n.t("activerecord.attributes.legal/attorney.title_clarification")}
                    />
                </div>
            )}
        </React.Fragment>
    ));
};

CheckboxList.propTypes = {
    elements: PropTypes.array.isRequired,
    inputName: PropTypes.string.isRequired,
    withPin: PropTypes.bool,
    pinning: PropTypes.string,
    pinned: PropTypes.array,
    handlePin: PropTypes.func,
    counter: PropTypes.object,
};

export default CheckboxList;
