import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/pro-solid-svg-icons";
import _isUndefined from "lodash/isUndefined";
import _isEmpty from "lodash/isEmpty";
import { IndustriesList } from "../general";
import AttorneyModal from "../modals/AttorneyModal";
import I18n from "../../../../utils/i18n";
import textWithClarification from "../../../../utils/textWithClarification";

const CardLabel = ({ url, icon, linkType }) => (
    <>
        {!_isEmpty(url) && (
            <span className="fa-lg text-primary mr-4">
                <a href={`${_isUndefined(linkType) ? "" : linkType}${url}`}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            </span>
        )}
    </>
);

const AttorneyCard = ({ attorney }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Card className="mb-4">
            <Card.Body>
                <div>
                    <strong className="cursor-pointer text-primary" onClick={() => setShowModal(true)}>
                        {attorney.name}
                    </strong>
                </div>

                <div>
                    <small className="text-muted">
                        {textWithClarification(
                            I18n.t(`activerecord.attributes.legal/attorney.titles.${attorney.title}`),
                            attorney.title_clarification,
                        )}
                    </small>
                </div>

                <div className="mt-2">
                    <CardLabel url={attorney.linkedin_url} icon={faLinkedin} />
                    <CardLabel url={attorney.phone} linkType="tel:" icon={faPhone} />
                    <CardLabel url={attorney.email} linkType="mailto:" icon={faEnvelope} />
                </div>

                {attorney.practice_areas?.length > 0 && (
                    <Row>
                        <Col>
                            <IndustriesList
                                industries={attorney.practice_areas}
                                showMoreKey="common.links.show_without_more"
                                name={I18n.t("activerecord.attributes.legal/attorney.practice_area_name")}
                            />
                        </Col>
                    </Row>
                )}
            </Card.Body>

            <AttorneyModal attorneyUid={attorney.uid} handleClose={() => setShowModal(false)} show={showModal} />
        </Card>
    );
};

CardLabel.propTypes = {
    url: PropTypes.string,
    icon: PropTypes.object.isRequired,
    linkType: PropTypes.string,
};

AttorneyCard.propTypes = {
    attorney: PropTypes.shape({
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        title_clarification: PropTypes.string,
        linkedin_url: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        practice_areas: PropTypes.array,
        uid: PropTypes.string.isRequired,
    }).isRequired,
};

export default AttorneyCard;
