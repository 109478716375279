import React from "react";
import { Form } from "react-bootstrap";
import _get from "lodash/get";
import { useFormikContext } from "formik";
import I18n from "../../../utils/i18n";

const ItemSource = () => {
    const { values, handleChange, errors } = useFormikContext();
    return (
        <Form.Group>
            <Form.Label>{I18n.t("faq.table.source_url")}</Form.Label>
            <Form.Control
                type="text"
                name="source_url"
                onChange={handleChange}
                value={values.source_url}
                isInvalid={_get(errors, "source_url")}
            />
            <Form.Control.Feedback type="invalid">{_get(errors, "source_url")}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default ItemSource;
