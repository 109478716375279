import React from "react";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import { sanitizeFormattedText } from "../../../../utils/sanitizeText";
import I18n from "../../../../utils/i18n";

const Logo = ({ path, name }) => {
    const initials = () => name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
    return (
        <div className="without-avatar w-100 img-raised rounded-circle img-fluid">
            {path ? (
                <div className="avatar avatar-round h-100 w-100">
                    <div className="avatar-image avatar-image-round" style={{ backgroundImage: `url("${path}")` }} />
                </div>
            ) : (
                <div className="letters text-uppercase">{initials() ? initials().join("") : " "}</div>
            )}
        </div>
    );
};

const ListValues = ({ label, values }) => {
    if (values.length === 0) return <></>;

    return (
        <>
            <strong>{label}:</strong>
            <ul>
                {_map(values, (value) => (
                    <li key={value.id}>{value.name}</li>
                ))}
            </ul>
        </>
    );
};

const LabelWithValue = ({ label, value }) => {
    if (_isEmpty(value)) return <></>;

    return (
        <div className="mb-2">
            <strong>{label}</strong>
            <div>{value}</div>
        </div>
    );
};

const NotableRecords = ({ label, records }) => (
    <>
        {records.length > 0 && (
            <>
                <strong>{label}:</strong>
                <ul>
                    {_map(records, (note) => (
                        <li key={note.id}>
                            {note.title}
                            <div dangerouslySetInnerHTML={{ __html: sanitizeFormattedText(note.body) }}></div>
                        </li>
                    ))}
                </ul>
            </>
        )}
    </>
);

const TextField = ({ label, value }) =>
    !_isEmpty(value) && (
        <>
            <strong>{label}:</strong>
            <div dangerouslySetInnerHTML={{ __html: sanitizeFormattedText(value) }}></div>
        </>
    );

const BarDetails = ({ values }) => {
    if (values.length === 0) return <></>;

    return (
        <>
            <strong>{I18n.t("activerecord.attributes.legal/attorney.bar_id")}:</strong>
            <ul>
                {_map(values, (value) => (
                    <li key={value.id}>
                        {value.bar_id} {value.country} {value.state}
                    </li>
                ))}
            </ul>
        </>
    );
};

Logo.propTypes = {
    name: PropTypes.string.isRequired,
    path: PropTypes.string,
};

ListValues.propTypes = {
    label: PropTypes.string.isRequired,
    values: PropTypes.array,
};

LabelWithValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
};

NotableRecords.propTypes = {
    label: PropTypes.string.isRequired,
    records: PropTypes.array,
};

TextField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
};

BarDetails.propTypes = {
    values: PropTypes.array,
};

export { TextField, Logo, NotableRecords, ListValues, LabelWithValue, BarDetails };
