import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ItemForm from "./components/ItemForm";
import EditPageTitle from "../../common/components/EditPageTitle";
import { editFAQItem, updateFAQItem } from "../../api/faqApi";
import { setAlert } from "../../state/alert/alertActions";
import I18n from "../../utils/i18n";

const FAQEditItemPage = () => {
    const params = useParams();
    const history = useHistory();
    const [item, setItem] = useState({});
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser.resource);

    useEffect(() => {
        if (!currentUser.superadmin) {
            history.push("/");
        }
    }, []);

    useEffect(() => {
        editFAQItem(params.id).then(({ data }) => setItem(data));
    }, []);

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        updateFAQItem(params.id, values).then(() => {
            dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
            resetForm(values);
            setSubmitting(false);
            history.push("/manage_faq");
        });
    };

    return (
        <ItemForm initialValues={item} onSubmit={onSubmit} title={<EditPageTitle resource={I18n.t("faq.item")} />} />
    );
};

export default FAQEditItemPage;
