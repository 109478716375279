import React from "react";
import { FieldArray, useFormikContext } from "formik";
import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import _isNumber from "lodash/isNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import IndustryInput from "../../OrganizationForm/sections/IndustryInput";

const PracticeArea = () => {
    const { values, errors, setFieldValue } = useFormikContext();

    const hideIndustry = (value, index) => {
        if (!value?._destroy) {
            const industryIndex = _findIndex(
                values,
                (industry) => industry.focus === value.focus && _isEmpty(industry.specialty) && !industry._destroy,
            );

            return industryIndex > -1 && !_isEmpty(value.specialty) && industryIndex !== index;
        } else return true;
    };

    const destroyIndustry = (index) => {
        setFieldValue(`practice_areas_attributes[${index}][_destroy]`, true);
    };

    return (
        <FieldArray name="practice_areas_attributes">
            {({ push, remove }) => (
                <>
                    {_map(values.practice_areas_attributes, (value, index) => (
                        <Form.Row
                            key={index}
                            className={classNames("align-items-baseline", {
                                "d-none": hideIndustry(value, index),
                            })}
                        >
                            <Form.Group className="col">
                                <IndustryInput
                                    parentName=""
                                    errors={errors.practice_areas_attributes || []}
                                    value={value}
                                    menuPlacement="bottom"
                                    index={index}
                                    push={push}
                                    values={values.practice_areas_attributes}
                                    fieldName="practice_areas_attributes"
                                    searchParams={{ legal: true }}
                                />

                                <div className="invalid-feedback">
                                    {errors.practice_areas_attributes &&
                                        errors.practice_areas_attributes[index] &&
                                        errors.practice_areas_attributes[index].industry_id}
                                </div>
                            </Form.Group>

                            <Form.Group className="col-auto">
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        if (_isNumber(value.industry_id)) {
                                            if (_isEmpty(value.specialty)) {
                                                _map(
                                                    values,
                                                    (industry, i) =>
                                                        industry.focus === value.focus && destroyIndustry(i),
                                                );
                                            } else destroyIndustry(index);
                                        } else {
                                            remove(index);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    ))}

                    <Form.Group>
                        <Button variant="outline-secondary" onClick={() => push({ industry_id: "" })}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </Form.Group>
                </>
            )}
        </FieldArray>
    );
};

export default PracticeArea;
