import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _join from "lodash/join";
import _reject from "lodash/reject";
import _map from "lodash/map";
import Table from "../../../common/components/Table";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import I18n from "../../../utils/i18n";
import ConfirmAction from "../../../common/components/ConfirmAction";
import { getFAQItems, removeFAQItem } from "../../../api/faqApi";

const ActionsCell = ({ row: { original }, updateData }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const onRemove = () => {
        removeFAQItem(original.id).finally(() => {
            updateData(original.id);
            setShowConfirmationModal(false);
        });
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item as={Link} to={`/faq/items/${original.id}`}>
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        {I18n.t("common.links.edit")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowConfirmationModal(true)}>
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        {I18n.t("common.links.delete")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmAction
                show={showConfirmationModal}
                onHide={() => setShowConfirmationModal(false)}
                onSubmit={onRemove}
                confirm="common.links.text_ok"
                title={I18n.t("faq.remove.title")}
                text={I18n.t("faq.remove.confirmation")}
            />
        </>
    );
};

const TitleCell = ({ row: { original } }) => <div className="d-flex flex-column">{original.title}</div>;

const SourceCell = ({ row: { original } }) => (
    <div className="d-flex flex-column preview-column">
        <Accordion>
            <Accordion.Toggle as={Button} variant="link" eventKey={original.id}>
                Preview
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={original.id}>
                <div>
                    <iframe
                        src={original.source_url}
                        loading="lazy"
                        allow="clipboard-write"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                        className="faq-frame"
                    ></iframe>
                </div>
            </Accordion.Collapse>
        </Accordion>
    </div>
);

const PermissionsCell = ({ row: { original } }) => (
    <>
        {_join(
            _map(
                original.permissions,
                (permission) =>
                    `${I18n.t(`activerecord.attributes.organization.permissions.${permission.name}`)}
                `,
            ),
            ",  ",
        )}
    </>
);

const ItemsTable = () => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("faq.table.title"),
                accessor: "title",
                Cell: TitleCell,
            },
            {
                Header: I18n.t("faq.table.source_url"),
                accessor: "source_url",
                Cell: SourceCell,
            },
            {
                Header: I18n.t("faq.table.item_permissions_attributes"),
                accessor: "item_permissions_name",
                Cell: PermissionsCell,
                isSorted: false,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} updateData={updateData} />,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const updateData = (itemId) => {
        setData((items) => _reject(items, ["id", itemId]));
    };

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "id", desc: true };
        setLoading(true);

        getFAQItems(pageIndex + 1, {
            sorts: `${sort.id} ${sort.desc ? "desc" : "asc"}`,
        }).then((response) => {
            if (fetchId === fetchIdRef.current) {
                setData(response.data.records);
                setPageCount(response.data.page_count);
                setLoading(false);
            }
        });
    }, []);

    return <Table columns={columns} data={data} fetchData={fetchData} pageCount={pageCount} loading={loading} />;
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
        }),
    }),
    updateData: PropTypes.func.isRequired,
};

TitleCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }),
    }),
};

SourceCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            source_url: PropTypes.string.isRequired,
        }),
    }),
};

PermissionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            permissions: PropTypes.arrayOf(
                PropTypes.shape({
                    permission_name: PropTypes.string,
                }),
            ).isRequired,
        }),
    }),
};

export default ItemsTable;
