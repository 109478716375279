import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import _includes from "lodash/includes";
import I18n from "../../../../utils/i18n";
import ConfirmAction from "../../../../common/components/ConfirmAction";
import ProfileCard from "../../../CompanyProfilesPage/components/ProfilesSection/ProfileCard";

const ProfileCardDashboard = ({ companyIds, handleCheckboxChange, handleRemoveFromList, hideActions, profile }) => {
    const [removeModalShow, setRemoveModalShow] = useState(false);

    return (
        <Row>
            <Col sm={1}>
                <Form.Check
                    custom
                    className="align-vertical-center"
                    type="checkbox"
                    id={`list-${profile.id}`}
                    checked={_includes(companyIds, profile.id)}
                    onChange={(event) => handleCheckboxChange(event, profile.id)}
                />
            </Col>
            <Col sm={11}>
                <ProfileCard
                    {...profile}
                    filtered={false}
                    actions={
                        hideActions ? (
                            <></>
                        ) : (
                            <>
                                <Button variant="outline-secondary" onClick={() => setRemoveModalShow(true)}>
                                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                    {I18n.t("companies.lists.actions.remove_from_list")}
                                </Button>
                                <ConfirmAction
                                    show={removeModalShow}
                                    onHide={() => setRemoveModalShow(false)}
                                    onSubmit={() =>
                                        handleRemoveFromList({
                                            uid: profile.uid,
                                            id: profile.id,
                                            setModalShow: setRemoveModalShow,
                                        })
                                    }
                                    confirm="common.links.text_ok"
                                    title={I18n.t("tables.lists.remove_company_from_list.title")}
                                    text={I18n.t("tables.lists.remove_company_from_list.text")}
                                />
                            </>
                        )
                    }
                />
            </Col>
        </Row>
    );
};

ProfileCardDashboard.propTypes = {
    handleCheckboxChange: PropTypes.func.isRequired,
    handleRemoveFromList: PropTypes.func.isRequired,
    companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    profile: PropTypes.shape({
        id: PropTypes.number.isRequired,
        uid: PropTypes.string.isRequired,
    }).isRequired,
    hideActions: PropTypes.bool.isRequired,
};

export default ProfileCardDashboard;
