import React from "react";
import { useFormikContext } from "formik";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import AttorneyLabel from "./AttorneyLabel";

const AttorneyField = ({ name, type = "text" }) => {
    const { handleChange, values, errors } = useFormikContext();
    return (
        <Form.Row>
            <Form.Group as={Col}>
                <AttorneyLabel name={name} />
                <Form.Control
                    type={type}
                    name={name}
                    onChange={handleChange}
                    value={values[name] || ""}
                    isInvalid={errors[name]}
                />
                <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
    );
};

AttorneyField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export default AttorneyField;
