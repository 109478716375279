import React, { useState, useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Container, Dropdown } from "react-bootstrap";
import _isUndefined from "lodash/isUndefined";
import _compact from "lodash/compact";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _reject from "lodash/reject";
import I18n from "../../../../utils/i18n";
import Table from "../../Table";
import { deleteOrganizationLocation, getOrganizationLocations } from "../../../../api/locationsApi";
import ThreeDotsToggle from "../../Table/ThreeDotsToggle";
import DeleteAction from "../../DeleteAction";

const NameCell = ({ row: { original } }) => (
    <div className={classNames({ "font-weight-bold": original.location_type_required })}>{original.name}</div>
);

const LocationTypeNameCell = ({ row: { original } }) => (
    <div className={classNames({ "font-weight-bold": original.location_type_required })}>
        {original.location_type_name}
    </div>
);

const CountryCell = ({ row: { original } }) => (
    <div className={classNames({ "font-weight-bold": original.location_type_required })}>{original.country.name}</div>
);

const AddressCell = ({ row: { original } }) => (
    <div className="d-flex flex-column text-left">
        <span>{_compact([original.address_line1, original.address_line2]).join(" ")}</span>
        <small className="text-muted">
            {_compact([_compact([original.city, original.state?.name]).join(", "), original.zip_code]).join(" ")}
        </small>
    </div>
);

const ActionsCell = ({ row: { original }, organizationUid, updateData }) => {
    const [deleteLocationModalShow, setDeleteLocationModalShow] = useState(false);

    const onDeleteLocationSubmit = () => {
        deleteOrganizationLocation(organizationUid, original.id).then(() => {
            updateData(original.id);
            setDeleteLocationModalShow(false);
        });
    };

    return (
        <>
            <Dropdown drop="left">
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item as={Link} to={`/organizations/${organizationUid}/locations/${original.id}/edit`}>
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        {I18n.t("common.links.edit")}
                    </Dropdown.Item>
                    {original.location_type_required || (
                        <Dropdown.Item as={Link} onClick={() => setDeleteLocationModalShow(true)} to="#">
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            {I18n.t("common.links.delete")}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <DeleteAction
                show={deleteLocationModalShow}
                onSubmit={onDeleteLocationSubmit}
                onHide={() => setDeleteLocationModalShow(false)}
                resource={I18n.t("activerecord.models.location.one")}
            />
        </>
    );
};

const LocationsTab = ({ organizationUid }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.location.name"),
                accessor: "name",
                Cell: NameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.location_type.name"),
                accessor: "location_type_name",
                Cell: LocationTypeNameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.location.country"),
                accessor: "country",
                Cell: CountryCell,
            },
            {
                Header: I18n.t("activerecord.attributes.location.address"),
                id: "address",
                isSorted: false,
                Cell: AddressCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} organizationUid={organizationUid} updateData={updateData} />,
            },
        ],
        [organizationUid],
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "default", desc: false };

        if (_isUndefined(organizationUid)) {
            setData([]);
            setPageCount(0);
        } else {
            setLoading(true);

            getOrganizationLocations(organizationUid, pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then(
                (response) => {
                    if (fetchId === fetchIdRef.current) {
                        setData(response.data.records);
                        setPageCount(response.data.page_count);
                        setLoading(false);
                    }
                },
            );
        }
    }, []);

    const updateData = (locationId) => {
        setData((locations) => _reject(locations, ["id", locationId]));
    };

    return (
        <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
            <h4>{I18n.t("tables.locations.notice")}</h4>
            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                updateData={updateData}
                loading={loading}
                pageCount={pageCount}
            />
        </Container>
    );
};

NameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            name: PropTypes.string,
            location_type_required: PropTypes.bool.isRequired,
        }),
    }),
};

LocationTypeNameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            location_type_name: PropTypes.string.isRequired,
            location_type_required: PropTypes.bool.isRequired,
        }),
    }),
};

CountryCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            country: PropTypes.shape({
                name: PropTypes.string,
            }),
            location_type_required: PropTypes.bool.isRequired,
        }),
    }),
};

AddressCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            address_line1: PropTypes.string,
            address_line2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
            }),
            zip_code: PropTypes.string,
            location_type_required: PropTypes.bool.isRequired,
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            location_type_required: PropTypes.bool.isRequired,
        }),
    }),
    organizationUid: PropTypes.string.isRequired,
    updateData: PropTypes.func.isRequired,
};

LocationsTab.propTypes = {
    organizationUid: PropTypes.string.isRequired,
};

export default LocationsTab;
