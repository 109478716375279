import React, { useCallback, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import _join from "lodash/join";
import _map from "lodash/map";
import I18n from "../../../../utils/i18n";
import { getTemplates } from "../../../../api/widgetsTemplatesApi";
import TemplatesTable from "./TemplatesTable";
import TooltipComponent from "../../../../common/components/DiversityPage/TooltipComponent";

const Templates = () => {
    const PER_PAGE = 5;
    const [negotiations, setNegotiations] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [page, setPage] = useState(1);
    const defaultSortBy = { id: "created_at", desc: true };
    const [pagesCount, setPagesCount] = useState(0);
    const goToPage = ({ selected }) => setPage(selected + 1);
    const [activeId, setActiveId] = useState("favorites");
    const filters = {
        favorites: { favorites: true },
        my_templates: { template_info_global_in: false },
        global_templates: { template_info_global_in: true },
    };

    const fetchData = useCallback(
        ({ sortBy }) => {
            const sort = sortBy[0] || defaultSortBy;
            setIsFetching(true);
            const ordering = sort.desc ? "desc" : "asc";
            const orderBy =
                sort.id === "owner_user_name" ? ["owner_user_first_name", "owner_user_last_name"] : [sort.id];

            getTemplates({
                q: {
                    tab_filter: {
                        ...filters[activeId],
                    },
                    s: _join(
                        _map(orderBy, (key) => `${key} ${ordering}`),
                        ", ",
                    ),
                    page: page,
                    per_page: PER_PAGE,
                },
            })
                .then(({ data: { records, pages_count } }) => {
                    setNegotiations(records);
                    setPagesCount(pages_count);
                })
                .catch(() => setNegotiations([]))
                .finally(() => setIsFetching(false));
        },
        [page, activeId],
    );
    const titleWithTooltip = (key) => (
        <>
            <span className="mr-1">{I18n.t(`home.templates.tabs.${key}.title`)}</span>
            <TooltipComponent content={I18n.t(`home.templates.tabs.${key}.tip`)} icon={faCircleExclamation} isLink={false} />
        </>
    );

    const table = (key) =>
        key === activeId && (
            <>
                <TemplatesTable
                    fetchData={fetchData}
                    isFetching={isFetching}
                    data={negotiations}
                    setNegotiations={setNegotiations}
                />
                {pagesCount > 1 && (
                    <div className="pagination justify-content-center mb-3">
                        <ReactPaginate
                            previousLabel="«"
                            nextLabel="»"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={goToPage}
                            containerClassName="pagination pagination-sm mb-0"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            nextClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            forcePage={page - 1}
                        />
                    </div>
                )}
            </>
        );

    return (
        <>
            <h3>{I18n.t("home.templates.header")}</h3>
            <Card className="mb-2">
                <Card.Body>
                    <Tabs
                        activeKey={activeId}
                        onSelect={(key) => {
                            setPage(1);
                            setActiveId(key);
                        }}
                    >
                        <Tab eventKey="favorites" title={titleWithTooltip("favorites")}>
                            {table("favorites")}
                        </Tab>
                        <Tab title={titleWithTooltip("my_templates")} eventKey="my_templates">
                            {table("my_templates")}
                        </Tab>
                        <Tab title={titleWithTooltip("global_templates")} eventKey="global_templates">
                            {table("global_templates")}
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </>
    );
};
export default Templates;
