import React, { useState } from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import _slice from "lodash/slice";
import _isEmpty from "lodash/isEmpty";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _lowerCase from "lodash/lowerCase";
import _uniqBy from "lodash/uniqBy";
import _range from "lodash/range";
import { useField } from "formik";
import { Collapse, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import I18n from "../../../../../utils/i18n";
import CheckboxList from "./CheckboxList";
import IndeterminateCheck from "../../../../../common/components/IndeterminateCheck";

const NUMBER_OF_VISIBLE_ELEMENTS = 3;

const MultiSelectMenu = ({ title, inputName, elements, fetching, withPin = false, pinned, pinFunction, counter }) => {
    const [, meta, helper] = useField(inputName);
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [pinning, setPinning] = useState(null);
    const visibleElements = _isEmpty(searchValue)
        ? elements
        : _filter(elements, (element) => _includes(_lowerCase(element.name), _lowerCase(searchValue)));

    const handleOpen = () => setOpen(!open);
    const onSearch = (event) => setSearchValue(event.target.value);
    const onSelectAll = (event) => {
        if (event.target.checked) {
            helper.setValue(
                elements.reduce((result, element) => {
                    result[element.id] = true;
                    return result;
                }, {}),
            );
        } else {
            helper.setValue({});
        }
    };

    const isAllSelected = () => elements.every((element) => meta.value[element.id]);
    const isSomeSelected = () => elements.some((element) => meta.value[element.id]);

    const onClear = () => {
        setSearchValue("");
        helper.setValue({});
        setOpen(false);
    };

    const getShortListElements = () => {
        let checkedElements = [];

        if (!open) {
            checkedElements = _filter(visibleElements, (visibleElement) => meta.value[visibleElement.id]);
        }

        return _slice(_uniqBy(checkedElements.concat(visibleElements), "id"), 0, NUMBER_OF_VISIBLE_ELEMENTS);
    };

    const handlePin = (filterKey, elementId, pin) => {
        setPinning(elementId);
        pinFunction(filterKey, elementId, pin).finally(() => setPinning(null));
    };

    return (
        <div className="multi-select-menu">
            <div className="d-flex align-items-center justify-content-between">
                <strong>{title}</strong>
                <small className="text-muted cursor-pointer" onClick={onClear}>
                    {I18n.t("common.clear")}
                </small>
            </div>
            {fetching ? (
                <Form className="mt-2">
                    {_map(_range(NUMBER_OF_VISIBLE_ELEMENTS), (item) => (
                        <Skeleton key={`loader-${inputName}-${item}`} />
                    ))}
                </Form>
            ) : (
                <Form className="mt-2">
                    <Form.Control
                        placeholder={I18n.t("common.placeholders.search")}
                        onChange={onSearch}
                        value={searchValue}
                        className="mb-2"
                    />
                    <IndeterminateCheck
                        key={`${inputName}-all`}
                        type="checkbox"
                        name={inputName}
                        id={`${inputName}-all`}
                        label={I18n.t("companies.profiles.sections.filters.counter", {
                            name: I18n.t("common.select_all"),
                            count: counter?.total,
                        })}
                        onChange={onSelectAll}
                        className="mb-2"
                        checked={isAllSelected()}
                        indeterminate={isSomeSelected() && !isAllSelected()}
                    />
                    <div className="checkbox-list">
                        <CheckboxList
                            inputName={inputName}
                            elements={getShortListElements()}
                            withPin={withPin}
                            pinned={pinned}
                            pinning={pinning}
                            handlePin={handlePin}
                            counter={counter}
                        />
                        <Collapse in={open}>
                            <div>
                                <CheckboxList
                                    inputName={inputName}
                                    elements={_slice(visibleElements, NUMBER_OF_VISIBLE_ELEMENTS)}
                                    withPin={withPin}
                                    pinned={pinned}
                                    pinning={pinning}
                                    handlePin={handlePin}
                                    counter={counter}
                                />
                            </div>
                        </Collapse>
                    </div>
                    {elements.length > NUMBER_OF_VISIBLE_ELEMENTS && (
                        <div
                            onClick={handleOpen}
                            className={classNames("mt-2 rotate-arrow cursor-pointer", { "opposite-collapsed": open })}
                        >
                            <small className="d-flex align-items-center">
                                More
                                <FontAwesomeIcon icon={faChevronDown} className="ml-2 align-baseline arrow" size="xs" />
                            </small>
                        </div>
                    )}
                </Form>
            )}
        </div>
    );
};

MultiSelectMenu.propTypes = {
    title: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    elements: PropTypes.array,
    fetching: PropTypes.bool,
    withPin: PropTypes.bool,
    pinned: PropTypes.array,
    pinFunction: PropTypes.func,
    counter: PropTypes.object,
};

export default MultiSelectMenu;
