import { useEffect, useState } from "react";
import _isFunction from "lodash/isFunction";
import { useLocation } from "react-router-dom";

const useTabs = (initialTab) => {
    const [activeTab, _setActiveTab] = useState(initialTab);
    const location = useLocation();

    const setActiveTab = (tab) => {
        location.hash = tab;
        _setActiveTab(tab);
    };

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            setActiveTab(hash.replace("#", ""));
        } else {
            if (_isFunction(initialTab)) {
                setActiveTab(initialTab());
            } else {
                setActiveTab(initialTab);
            }
        }
    }, [initialTab]);

    return [activeTab, setActiveTab];
};

export default useTabs;
