import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _map from "lodash/map";
import { getFAQ } from "../../api/faqApi";
import I18n from "../../utils/i18n";
import ItemCard from "./componets/ItemCard";

const FAQPage = () => {
    const currentOrganization = useSelector((state) => state.currentActor.resource.organization);

    const history = useHistory();
    const [faqItems, setFaqItems] = useState([]);

    useEffect(() => {
        if (!currentOrganization) {
            history.push("/");
        }
    }, []);

    useEffect(() => getFAQ().then(({ data }) => setFaqItems(data)), []);

    return (
        <>
            <div className="top-bar">
                <Col>
                    <Container fluid>
                        <div className="py-3" />
                    </Container>
                </Col>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("faq.title")}</h2>
                            </div>
                        </Container>
                    </Col>

                    <Container fluid>
                        {_map(faqItems, (item) => (
                            <ItemCard item={item} key={item.id} />
                        ))}
                    </Container>
                </div>
            </div>
        </>
    );
};

export default FAQPage;
