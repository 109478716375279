import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import _findIndex from "lodash/findIndex";
import _forEach from "lodash/forEach";
import _union from "lodash/union";
import { useFormikContext } from "formik";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _map from "lodash/map";
import _reject from "lodash/reject";
import { getPermissions } from "../../../api/organizationApi";
import I18n from "../../../utils/i18n";

const ItemPermissionsSelect = () => {
    const { values, setFieldValue } = useFormikContext();
    const [permissionList, setPermissionList] = useState([]);

    useEffect(() => {
        getPermissions().then(({ data }) =>
            setPermissionList(
                _map(data, (permission) => ({
                    id: permission.id,
                    name: I18n.t(`activerecord.attributes.organization.permissions.${permission.name}`),
                })),
            ),
        );
    }, []);

    const setDestroyFor = (valueIndex, destroy) => {
        setFieldValue(`item_permissions_attributes[${valueIndex}][_destroy]`, destroy);
    };

    const getSelectedOptions = () =>
        _filter(permissionList, (option) =>
            _includes(
                _map(_reject(values.item_permissions_attributes, ["_destroy", true]), "permission_id"),
                option.id,
            ),
        );

    return (
        <Form.Group>
            <Form.Label>{I18n.t("faq.table.item_permissions_attributes")}</Form.Label>
            <Select
                isMulti
                className="react-select w-100"
                classNamePrefix="react-select"
                onChange={(option, meta) => {
                    if (meta.action === "remove-value") {
                        const index = _findIndex(values.item_permissions_attributes, [
                            "permission_id",
                            meta.removedValue.id,
                        ]);
                        setDestroyFor(index, true);
                    }
                    if (meta.action === "clear") {
                        _forEach(meta.removedValues, (value) => {
                            const index = _findIndex(values.item_permissions_attributes, ["permission_id", value.id]);
                            setDestroyFor(index, true);
                        });
                    }
                    if (meta.action === "select-option") {
                        setFieldValue(
                            "item_permissions_attributes",
                            _union(values.item_permissions_attributes, [
                                { permission_id: meta.option.id, _destroy: false },
                            ]),
                        );
                    }
                }}
                options={permissionList}
                value={getSelectedOptions()}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                menuPosition="fixed"
            />
        </Form.Group>
    );
};

export default ItemPermissionsSelect;
