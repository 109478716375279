import React, { useState } from "react";
import { Accordion, Card, Spinner } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";

const ItemCard = ({ item }) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const handleIframeLoad = () => {
        setLoading(false);
    };

    return (
        <>
            <Accordion className="mb-2">
                <Accordion.Toggle
                    as="div"
                    eventKey={`faq-${item.id}`}
                    onClick={() => setOpen((prevState) => !prevState)}
                >
                    <Card.Header className={classNames("accordion-card-header", { "nonactive-card-header": !open })}>
                        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} className="mr-2 arrow" />
                        <strong>{item.title}</strong>
                    </Card.Header>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={`faq-${item.id}`}>
                    <Card.Body>
                        {isLoading && (
                            <div className="d-flex justify-content-center pt-5">
                                <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                            </div>
                        )}
                        <div className={classNames({ "faq-container": !isLoading })}>
                            <iframe
                                src={item.source_url}
                                loading="lazy"
                                allow="clipboard-write"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                                className="faq-frame"
                                onLoad={handleIframeLoad}
                            ></iframe>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </>
    );
};

ItemCard.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        source_url: PropTypes.string.isRequired,
    }).isRequired,
};

export default ItemCard;
