import qs from "qs";
import API, { buildFormData } from "../utils/api";

export function getFAQItems(page, params) {
    return API.get(`/faq/items?${qs.stringify({ page: page, page_size: 10, ...params })}`, {
        arrayFormat: "brackets",
        encode: false,
    });
}

export function getFAQ() {
    return API.get("/faq/page");
}

export function createFAQItem(values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.post(`/faq/items`, formData, { headers: { "Content-Type": "multipart/form-data" } });
}

export function updateFAQItem(faqId, values) {
    let formData = new FormData();

    buildFormData(formData, values);

    return API.patch(`/faq/items/${faqId}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
}

export function editFAQItem(faqId) {
    return API.get(`/faq/items/${faqId}/edit`);
}

export function removeFAQItem(faqId) {
    return API.delete(`/faq/items/${faqId}`);
}
