import React from "react";
import { Form } from "react-bootstrap";
import _get from "lodash/get";
import { useFormikContext } from "formik";
import I18n from "../../../utils/i18n";

const ItemTitle = () => {
    const { values, handleChange, errors } = useFormikContext();
    return (
        <Form.Group>
            <Form.Label>{I18n.t("faq.table.title")}</Form.Label>
            <Form.Control
                type="text"
                name="title"
                onChange={handleChange}
                value={values.title}
                isInvalid={_get(errors, "title")}
            />
            <Form.Control.Feedback type="invalid">{_get(errors, "title")}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default ItemTitle;
