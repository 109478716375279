import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import classNames from "classnames";
import { useFormikContext } from "formik";
import _find from "lodash/find";
import { AttorneyLabel } from "./index";
import AttorneyField from "./AttorneyField";

const AttorneySelect = ({ name, field, options }) => {
    const { setFieldValue, values, errors } = useFormikContext();

    return (
        <>
            <Form.Row>
                <Form.Group as={Col}>
                    <AttorneyLabel name={name || field} />
                    <Select
                        className={classNames("react-select w-100", {
                            "is-invalid": errors[field],
                        })}
                        classNamePrefix="react-select"
                        onChange={(option) => {
                            setFieldValue(field, option.id);

                            if (option.id !== "other" && values[`${field}_clarification`]) {
                                setFieldValue(`${field}_clarification`, null);
                            }
                        }}
                        value={_find(options, ["id", values[field]])}
                        options={options}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        menuPosition="fixed"
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 3 }) }}
                    />
                    <Form.Control.Feedback type="invalid">{errors[field]}</Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            {values[field] === "other" && <AttorneyField name={`${field}_clarification`} />}
        </>
    );
};

AttorneySelect.propTypes = {
    name: PropTypes.string,
    field: PropTypes.string.isRequired,
    options: PropTypes.array,
};

export default AttorneySelect;
