import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import _includes from "lodash/includes";
import _split from "lodash/split";
import _union from "lodash/union";
import _each from "lodash/each";
import { Card, Tab, Tabs } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import I18n from "../../../../utils/i18n";
import MultiSelectMenu from "./MultiSelectMenu";
import RangeInput from "./RangeInput";
import GroupSelectMenu from "./GroupSelectMenu";
import ListsSelectMenu from "./ListsSelectMenu";
import DiverseOwnedMenu from "./DiverseOwnedMenu";
import PinContext from "./PinContext";
import { pinFilter, unpinFilter } from "../../../../api/companiesApi";

const FiltersSection = ({ filterElementsFetching, filterElements, applyFilters, filterCounter }) => {
    const [filterSettings, setFilterSettings] = useContext(PinContext);

    const formik = useFormik({
        initialValues: {
            employees: [0, filterElements.max_employees],
            sales: [0, filterElements.max_sales],
            industries: [],
            locations: [],
            location_types: {},
            certificates: {},
            companies_lists: [],
            court_admissions: {},
            attorneys_titles: {},
            badges: [],
            diverse_owned: {
                women_owned: false,
                minority_owned: false,
                ethnicity: [],
                lgbtq_owned: false,
                veteran_owned: false,
                differently_abled_owned: false,
                disadvantaged_business: false,
            },
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (formik.values !== formik.initialValues) {
            applyFilters(formik.values);
        }
    }, [formik.values]);

    const handlePin = (filterKey, element, pin = true) => {
        const perform = pin ? pinFilter : unpinFilter;
        const params = { company_profiles: { [filterKey]: element } };

        return perform(params).then(({ data }) => setFilterSettings(data.company_profiles));
    };

    const getMarkedElements = (pinnedElement) => {
        let markedElements = [];

        _each(pinnedElement, (element) => {
            const [groupName, subgroupName, elementName] = _split(element, "_");

            markedElements = _union(markedElements, [groupName]);

            if (subgroupName) {
                markedElements = _union(markedElements, [`${groupName}_${subgroupName}`]);
            }

            if (elementName) {
                markedElements = _union(markedElements, [`${groupName}_${subgroupName}_${elementName}`]);
            }
        });

        return markedElements;
    };

    const groupSelectSort = (elements, markedElements) => {
        const markedByGroup = (e) => _includes(markedElements, e.group_name);
        const markedBySubgroup = (e) => _includes(markedElements, `${e.group_name}_${e.subgroup_name}`);
        const markedByElement = (e) => _includes(markedElements, `${e.group_name}_${e.subgroup_name}_${e.name}`);
        const marked = (e) => markedByElement(e) || markedBySubgroup(e) || markedByGroup(e);

        const compareByGroup = (a, b) => a.group_name.localeCompare(b.group_name);
        const compareBySubgroup = (a, b) => (a.subgroup_name || "").localeCompare(b.subgroup_name || "");
        const compareByElement = (a, b) => (a.name || "").localeCompare(b.name || "");
        const compare = (a, b) => compareByGroup(a, b) || compareBySubgroup(a, b) || compareByElement(a, b);

        return [...elements].sort((a, b) => {
            if (!marked(a)) {
                return marked(b) ? 1 : compare(a, b);
            }

            if (!marked(b)) {
                return -1;
            }

            if (!markedByGroup(a) || !markedByGroup(b)) {
                return markedByGroup(a) ? -1 : markedByGroup(b) ? 1 : compareByGroup(a, b);
            }

            if (a.group_name !== b.group_name) {
                return compareByGroup(a, b);
            }

            if (!markedBySubgroup(a) || !markedBySubgroup(b)) {
                return markedBySubgroup(a) ? -1 : markedBySubgroup(b) ? 1 : compareBySubgroup(a, b);
            }

            if (a.subgroup_name !== b.subgroup_name) {
                return compareBySubgroup(a, b);
            }

            if (!markedByElement(a) || !markedByElement(b)) {
                return markedByElement(a) ? -1 : markedByElement(b) ? 1 : compareByElement(a, b);
            }

            return compareByElement(a, b);
        });
    };

    return (
        <FormikProvider value={formik}>
            <Card className="border-0 sticky-top" id="filters-section">
                <Card.Body className="overflow-y-auto overflow-x-hidden">
                    <Card.Title>
                        <h3>{I18n.t("companies.profiles.sections.filters.title")}</h3>
                    </Card.Title>

                    <Tabs defaultActiveKey="companies" id="uncontrolled-tab-example" className="mb-3">
                        <Tab
                            eventKey="companies"
                            title={I18n.t("companies.profiles.sections.filters.section.companies")}
                        >
                            <Card.Body className="p-2">
                                <ListsSelectMenu
                                    lists={filterElements.companies_lists}
                                    sharedLists={filterElements.shared_companies_lists}
                                    fetching={filterElementsFetching}
                                    counter={filterCounter?.companies_lists}
                                />
                                <hr />
                                {filterElements.max_employees > 0 && (
                                    <>
                                        <RangeInput
                                            title={I18n.t("companies.profiles.sections.filters.employees")}
                                            values={formik.values.employees}
                                            setValues={(values) => formik.setFieldValue("employees", values)}
                                            maxValue={filterElements.max_employees}
                                            fetching={filterElementsFetching}
                                        />

                                        <hr />
                                    </>
                                )}
                                {filterElements.max_sales > 0 && (
                                    <>
                                        <RangeInput
                                            title={I18n.t("companies.profiles.sections.filters.sales")}
                                            values={formik.values.sales}
                                            setValues={(values) => formik.setFieldValue("sales", values)}
                                            maxValue={filterElements.max_sales}
                                            fetching={filterElementsFetching}
                                        />

                                        <hr />
                                    </>
                                )}
                                <GroupSelectMenu
                                    inputName="industries"
                                    title={I18n.t("companies.profiles.sections.filters.industries")}
                                    elements={groupSelectSort(
                                        filterElements.industries,
                                        getMarkedElements(filterSettings.industries),
                                    )}
                                    fetching={filterElementsFetching}
                                    setFieldValue={formik.setFieldValue}
                                    values={formik.values.industries}
                                    pinned={filterSettings.industries}
                                    marked={getMarkedElements(filterSettings.industries)}
                                    pinFunction={handlePin}
                                    counter={filterCounter?.industries}
                                />
                                <hr />
                                <MultiSelectMenu
                                    inputName="location_types"
                                    title={I18n.t("companies.profiles.sections.filters.location_types")}
                                    elements={filterElements.location_types}
                                    fetching={filterElementsFetching}
                                    counter={filterCounter?.location_types}
                                />
                                <hr />
                                <GroupSelectMenu
                                    inputName="locations"
                                    title={I18n.t("companies.profiles.sections.filters.locations")}
                                    elements={groupSelectSort(
                                        filterElements.locations,
                                        getMarkedElements(filterSettings.locations),
                                    )}
                                    fetching={filterElementsFetching}
                                    setFieldValue={formik.setFieldValue}
                                    values={formik.values.locations}
                                    pinned={filterSettings.locations}
                                    marked={getMarkedElements(filterSettings.locations)}
                                    pinFunction={handlePin}
                                    counter={filterCounter?.locations}
                                />
                                <hr />
                                <MultiSelectMenu
                                    inputName="certificates"
                                    title={I18n.t("companies.profiles.sections.filters.certificates")}
                                    elements={filterElements.certificates}
                                    fetching={filterElementsFetching}
                                    counter={filterCounter?.certificates}
                                />
                                <hr />
                                <DiverseOwnedMenu filterElements={filterElements} counter={filterCounter?.ownership} />
                            </Card.Body>
                        </Tab>
                        <Tab
                            eventKey="attrorneys"
                            title={I18n.t("companies.profiles.sections.filters.section.attorneys")}
                        >
                            <Card.Body>
                                <MultiSelectMenu
                                    inputName="court_admissions"
                                    title={I18n.t("companies.profiles.sections.filters.court_admissions")}
                                    elements={filterElements.court_admissions}
                                    fetching={filterElementsFetching}
                                    counter={filterCounter?.court_admissions}
                                />
                                <hr />
                                <MultiSelectMenu
                                    title={I18n.t("companies.profiles.sections.filters.attorneys_titles")}
                                    inputName="attorneys_titles"
                                    elements={filterElements.attorneys_titles}
                                    fetching={filterElementsFetching}
                                    counter={filterCounter?.attorneys_titles}
                                />
                            </Card.Body>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </FormikProvider>
    );
};

FiltersSection.propTypes = {
    filterElementsFetching: PropTypes.bool,
    filterElements: PropTypes.shape({
        max_employees: PropTypes.number.isRequired,
        max_sales: PropTypes.number.isRequired,
        industries: PropTypes.array,
        locations: PropTypes.array,
        location_types: PropTypes.array,
        court_admissions: PropTypes.array,
        attorneys_titles: PropTypes.array,
        certificates: PropTypes.array,
        companies_lists: PropTypes.array,
        shared_companies_lists: PropTypes.array,
        badges: PropTypes.array,
    }),
    applyFilters: PropTypes.func.isRequired,
    filterCounter: PropTypes.shape({
        industries: PropTypes.object,
        location_types: PropTypes.object,
        locations: PropTypes.object,
        certificates: PropTypes.object,
        companies_lists: PropTypes.object,
        shared_companies_lists: PropTypes.object,
        court_admissions: PropTypes.object,
        attorneys_titles: PropTypes.object,
        ownership: PropTypes.object,
    }),
};

export default FiltersSection;
