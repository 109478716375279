import React from "react";
import { Formik, Form as FormikForm } from "formik";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import PropTypes from "prop-types";
import TopBarWithBackLink from "../../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../../common/components/NavigationPrompt";
import ItemPermissionsSelect from "./ItemPermissionsSelect";
import I18n from "../../../utils/i18n";
import ItemSource from "./ItemSource";
import ItemTitle from "./ItemTitle";

const ItemForm = ({ initialValues, onSubmit, title }) => {
    const validationSchema = Yup.object().shape({
        title: Yup.string().max(255).required(),
        source_url: Yup.string().url().max(255).required(),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ dirty, isSubmitting, handleSubmit }) => (
                <FormikForm as={Form}>
                    <TopBarWithBackLink backPath="/manage_faq" linkName={I18n.t("faq.title")} />
                    <NavigationPrompt when={dirty} />
                    <div className="scrollable d-flex flex-column">
                        <div className="flex-grow-1 overflow-auto bg-white">
                            <Col>
                                <Container fluid className="py-3">
                                    {title}
                                </Container>
                                <Container>
                                    <ItemTitle />
                                    <ItemSource />
                                    <ItemPermissionsSelect />
                                </Container>
                            </Col>
                        </div>
                        <div className="bottom-navigation p-2">
                            <Col>
                                <Container fluid>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-actions">
                                            <Button
                                                className="ml-2 text-white"
                                                variant="primary"
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                {isSubmitting && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="mr-2"
                                                    />
                                                )}
                                                {I18n.t("common.links.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </Col>
                        </div>
                    </div>
                </FormikForm>
            )}
        </Formik>
    );
};

ItemForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.node.isRequired,
};

export default ItemForm;
