import React, { useState, useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Container, Card, Dropdown } from "react-bootstrap";
import _isUndefined from "lodash/isUndefined";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import _reject from "lodash/reject";
import I18n from "../../../../utils/i18n";
import Table from "../../Table";
import ThreeDotsToggle from "../../Table/ThreeDotsToggle";
import { getOrganizationAttorneys, deleteOrganizationAttorney } from "../../../../api/legalApi";
import CasesTable from "./CasesTable";
import DeleteAction from "../../DeleteAction";
import TextWithClarification from "../../../../utils/textWithClarification";

const TitleCell = ({
    row: {
        original: { title, title_clarification },
    },
}) => TextWithClarification(I18n.t(`activerecord.attributes.legal/attorney.titles.${title}`), title_clarification);

const NumberOfCasesCell = ({
    row: {
        getToggleRowExpandedProps,
        isExpanded,
        original: { number_of_cases },
    },
}) => (
    <div className="d-flex flex-row">
        <span {...getToggleRowExpandedProps()} className={classNames("expand mr-2", { down: isExpanded })}>
            <FontAwesomeIcon icon={faChevronRight} />
        </span>
        {number_of_cases}
    </div>
);

const ActionsCell = ({
    row: {
        original: { id },
    },
    organizationUid,
    updateData,
}) => {
    const [deleteAttorneyModalShow, setDeleteAttorneyModalShow] = useState(false);

    const onDeleteAttorneySubmit = (_event, { setIsSubmitting }) => {
        deleteOrganizationAttorney(organizationUid, id).then(() => {
            updateData(id);
            setDeleteAttorneyModalShow(false);
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <Dropdown drop="left">
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item as={Link} to={`/organizations/${organizationUid}/legal/attorneys/${id}/edit`}>
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        {I18n.t("common.links.edit")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} onClick={() => setDeleteAttorneyModalShow(true)} to="#">
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        {I18n.t("common.links.delete")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <DeleteAction
                resource={I18n.t("activerecord.models.legal/attorney.one")}
                onSubmit={onDeleteAttorneySubmit}
                show={deleteAttorneyModalShow}
                onHide={() => setDeleteAttorneyModalShow(false)}
            />
        </>
    );
};

const AttorneysTable = ({ organizationUid }) => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.legal/attorney.name"),
                accessor: "name",
            },
            {
                Header: I18n.t("activerecord.attributes.legal/attorney.title"),
                accessor: "title",
                Cell: TitleCell,
            },
            {
                Header: I18n.t("activerecord.attributes.legal/attorney.graduation_year"),
                accessor: "graduation_year",
                type: "number",
            },
            {
                Header: I18n.t("activerecord.attributes.legal/attorney.number_of_cases"),
                accessor: "number_of_cases",
                Cell: NumberOfCasesCell,
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} organizationUid={organizationUid} updateData={updateData} />,
            },
        ],
        [organizationUid],
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        ({ pageIndex, pageSize, sortBy, initialSortBy }) => {
            const fetchId = ++fetchIdRef.current;
            const sort = sortBy[0] || initialSortBy[0];

            if (_isUndefined(organizationUid)) {
                setData([]);
                setPageCount(0);
            } else {
                setLoading(true);

                getOrganizationAttorneys(organizationUid, {
                    page: pageIndex + 1,
                    page_size: pageSize,
                    column: sort.id,
                    order: sort.desc ? "desc" : "asc",
                }).then((response) => {
                    if (fetchId === fetchIdRef.current) {
                        setData(response.data.records);
                        setPageCount(response.data.page_count);
                        setLoading(false);
                    }
                });
            }
        },
        [organizationUid],
    );

    const updateData = (id) => {
        setData((attorneys) => _reject(attorneys, ["id", id]));
    };

    const renderRowSubComponent = useCallback(
        ({
            row: {
                original: { id },
            },
        }) => (
            <tr>
                <td colSpan={7} className="bg-light">
                    <Card>
                        <Card.Body>
                            <CasesTable attorneyId={id} organizationUid={organizationUid} />
                        </Card.Body>
                    </Card>
                </td>
            </tr>
        ),
        [],
    );

    return (
        <Container fluid className="mt-3 pt-2 scrollable tab-scrollable">
            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                initialSortBy={[{ id: "name", desc: false }]}
                renderRowSubComponent={renderRowSubComponent}
            />
        </Container>
    );
};

TitleCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            title: PropTypes.string.isRequired,
            title_clarification: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

NumberOfCasesCell.propTypes = {
    row: PropTypes.shape({
        getToggleRowExpandedProps: PropTypes.func.isRequired,
        isExpanded: PropTypes.bool,
        original: PropTypes.shape({
            number_of_cases: PropTypes.number.isRequired,
        }),
    }),
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    organizationUid: PropTypes.string.isRequired,
    updateData: PropTypes.func.isRequired,
};

AttorneysTable.propTypes = {
    organizationUid: PropTypes.string.isRequired,
};

export default AttorneysTable;
