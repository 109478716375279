import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import _find from "lodash/find";
import { Button, Col, Form } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import classNames from "classnames";
import I18n from "../../../../utils/i18n";
import LocationModal from "../../LocationForm/LocationModal";
import AttorneyLabel from "./AttorneyLabel";

const LocationInput = ({ locationOptions, organizationUid, setLocationOptions }) => {
    const { values, setFieldValue, errors } = useFormikContext();
    const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
    const [currentLocation, setCurrentLocation] = useState();

    useEffect(() => {
        setCurrentLocation(_find(locationOptions, ["id", values.location_id]));
    }, [locationOptions, values.location_id]);

    return (
        <Form.Row>
            <Form.Group as={Col}>
                <AttorneyLabel name="location_id" />
                <Select
                    className={classNames("react-select w-100", {
                        "is-invalid": errors.location_id,
                    })}
                    classNamePrefix="react-select"
                    onChange={(option) => setFieldValue("location_id", option.id)}
                    options={locationOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={currentLocation}
                    menuPosition="fixed"
                />
                <Form.Control.Feedback type="invalid">{errors.location_id}</Form.Control.Feedback>
                <Button variant="link" onClick={() => setShowCreateLocationModal(true)}>
                    {I18n.t("activerecord.attributes.legal/attorney.actions.add_location")}
                </Button>
                <LocationModal
                    organizationUid={organizationUid}
                    handleClose={() => setShowCreateLocationModal(false)}
                    show={showCreateLocationModal}
                    setLocationOptions={setLocationOptions}
                />
            </Form.Group>
        </Form.Row>
    );
};

LocationInput.propTypes = {
    locationOptions: PropTypes.array.isRequired,
    organizationUid: PropTypes.string.isRequired,
    setLocationOptions: PropTypes.func.isRequired,
};

export default LocationInput;
