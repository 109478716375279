import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createFAQItem } from "../../api/faqApi";
import PageTitle from "../../common/components/PageTitle";
import ItemForm from "./components/ItemForm";
import I18n from "../../utils/i18n";
import { setAlert } from "../../state/alert/alertActions";

const FAQNewItemPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser.resource);

    useEffect(() => {
        if (!currentUser.superadmin) {
            history.push("/");
        }
    }, []);

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        createFAQItem(values).then(() => {
            dispatch(setAlert({ translationKey: "common.alerts.successful_add", variant: "info" }));
            setSubmitting(false);
            resetForm(values);
            history.push("/manage_faq");
        });
    };

    return (
        <ItemForm
            initialValues={{ title: "", source_url: "", item_permissions_attributes: [] }}
            onSubmit={onSubmit}
            title={<PageTitle text={I18n.t("common.titles.new", { resource: I18n.t("faq.item") })} />}
        />
    );
};

export default FAQNewItemPage;
