import React, { useEffect } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import I18n from "../../utils/i18n";
import ItemsTable from "./components/ItemsTable";

const ManageFAQPage = () => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentUser.superadmin) {
            history.push("/");
        }
    }, []);

    return (
        <>
            <div className="top-bar">
                <Col>
                    <Container fluid>
                        <div className="py-3">
                            <Row className="justify-content-end">
                                <Col className="col-auto ">
                                    <Button variant="primary" href="/faq/items/new">
                                        {I18n.t("common.links.create")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
            </div>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("faq.title")}</h2>
                            </div>
                            <ItemsTable />
                        </Container>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default ManageFAQPage;
