import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import _find from "lodash/find";
import _toString from "lodash/toString";
import _isUndefined from "lodash/isUndefined";
import FormattedScore from "../../../../common/components/FormattedScore";
import Avatar from "../../../../common/components/Avatar";
import I18n from "../../../../utils/i18n";
import AddCompanyToListsModal from "../../../CompanyListsPage/components/AddCompanyToListsModal";
import RegularAddToListButton from "../../../CompanyListsPage/components/RegularAddToListButton";
import SolidAddToListButton from "../../../CompanyListsPage/components/SolidAddToListButton";
import AddToProjectAction from "../AddToProjectActions";
import AttorneysSection from "./AttorneysSection";

const ProfileCard = ({
    id,
    uid,
    name,
    logo_url,
    updated_at,
    jb_score,
    total_score,
    industries,
    certificates,
    locations_count,
    present_in_lists,
    legal,
    attorneys,
    filtered,
    actions = undefined,
}) => {
    const currentActor = useSelector((state) => state.currentActor.resource);
    const primaryIndustry = _find(industries, ["primary", true]);
    const primaryCertificate = _find(certificates, ["primary", true]);
    const [showAddListModal, setShowAddListModal] = useState(false);

    return (
        <Card className="mb-3">
            <Card.Body>
                <Container>
                    <Row>
                        <Col sm={2} className="d-flex align-items-center justify-content-center">
                            {currentActor.organization.permissions.company_profile_permission ? (
                                <Link to={`/companies/profiles/${uid}`}>
                                    <Avatar preview={logo_url} name={name} size="lg" />
                                </Link>
                            ) : (
                                <Avatar preview={logo_url} name={name} size="lg" />
                            )}
                        </Col>
                        <Col sm={10}>
                            <Row>
                                <Col>
                                    <h2 className="text-truncate text-primary">
                                        {currentActor.organization.permissions.company_profile_permission ? (
                                            <Link title={name} to={`/companies/profiles/${uid}`}>
                                                {name}
                                            </Link>
                                        ) : (
                                            name
                                        )}
                                    </h2>
                                </Col>
                                {legal && (
                                    <Col sm={2}>
                                        {(jb_score || total_score) && (
                                            <FormattedScore
                                                jbScore={_toString(jb_score)}
                                                totalScore={_toString(total_score)}
                                            />
                                        )}
                                    </Col>
                                )}
                            </Row>
                            {locations_count > 0 && (
                                <Row>
                                    <Col sm={2} className="text-muted">
                                        {I18n.t("companies.profiles.text.profile")}
                                    </Col>
                                    <Col>
                                        <Link to={`/companies/profiles/${uid}#locations`}>{locations_count}</Link>{" "}
                                        {I18n.t("activerecord.models.location.other")}
                                    </Col>
                                    <Col />
                                </Row>
                            )}
                            {primaryIndustry && (
                                <Row>
                                    <Col sm={2} className="text-muted">
                                        {I18n.t("activerecord.models.industry.one")}
                                    </Col>
                                    <Col>
                                        <div
                                            className="short industry-name"
                                            title={`${primaryIndustry.area}: ${primaryIndustry.focus}`}
                                        >{`${primaryIndustry.area}: ${primaryIndustry.focus}`}</div>
                                    </Col>
                                </Row>
                            )}

                            {primaryCertificate && (
                                <Row>
                                    <Col sm={2} className="text-muted">
                                        {I18n.t("activerecord.models.diversity/certificate")}
                                    </Col>
                                    <Col>{primaryCertificate.name}</Col>
                                </Row>
                            )}
                        </Col>
                    </Row>

                    {legal && (
                        <Row>
                            <Col>
                                <AttorneysSection
                                    organizationId={uid}
                                    filteredAttorneys={attorneys.filtered_attorneys?.records}
                                    attorneys={attorneys.records}
                                    filtered={filtered}
                                />
                            </Col>
                        </Row>
                    )}
                </Container>
            </Card.Body>
            <Card.Footer className="text-muted">
                <Row className="justify-content-between">
                    <Col sm={3} className="align-content-center">
                        {I18n.t("companies.profiles.text.updated", { date: moment(updated_at).fromNow() })}
                    </Col>
                    <Col sm={6}>
                        {currentActor.organization.uid !== uid && (
                            <div className="d-flex justify-content-end">
                                {!_isUndefined(actions) ? (
                                    actions
                                ) : (
                                    <>
                                        <AddToProjectAction organizationId={id} />

                                        {present_in_lists ? (
                                            <SolidAddToListButton onClick={() => setShowAddListModal(true)} />
                                        ) : (
                                            <RegularAddToListButton onClick={() => setShowAddListModal(true)} />
                                        )}

                                        <AddCompanyToListsModal
                                            organizationUid={uid}
                                            onClose={() => setShowAddListModal(false)}
                                            show={showAddListModal}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

ProfileCard.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    logo_url: PropTypes.string,
    present_in_lists: PropTypes.bool,
    jb_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    certificates: PropTypes.arrayOf(
        PropTypes.shape({
            primary: PropTypes.bool,
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    ),
    industries: PropTypes.arrayOf(
        PropTypes.shape({
            primary: PropTypes.bool,
            area: PropTypes.string,
        }),
    ),
    locations_count: PropTypes.number.isRequired,
    legal: PropTypes.bool,
    attorneys: PropTypes.shape({
        records: PropTypes.array,
        filtered_attorneys: PropTypes.shape({
            records: PropTypes.array,
        }),
    }),
    actions: PropTypes.node,
    filtered: PropTypes.bool,
};

export default ProfileCard;
