import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Badge } from "react-bootstrap";
import _map from "lodash/map";
import PropTypes from "prop-types";
import { Logo, NotableRecords, TextField, LabelWithValue, ListValues, BarDetails } from "../attorneys/AttorneyFields";
import I18n from "../../../../utils/i18n";
import { getAttorney } from "../../../../api/companiesApi";
import textWithClarification from "../../../../utils/textWithClarification";

const AttorneyModal = ({ attorneyUid, show, handleClose }) => {
    const [isLoading, setLoading] = useState(true);
    const [attorney, setAttorney] = useState(undefined);

    useEffect(() => {
        if (!show) return;

        getAttorney(attorneyUid)
            .then(({ data }) => setAttorney(data))
            .finally(() => setLoading(false));
    }, [attorneyUid, show]);

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <></>
                ) : (
                    <>
                        <Row>
                            <Col sm={4} className="d-flex">
                                <div className="attorney-avatar position-relative h-100">
                                    <Logo name={attorney.name} path={attorney.photo} />
                                </div>
                                <div className="ml-4">
                                    <strong>{attorney.name}</strong>
                                    <div>
                                        {textWithClarification(
                                            I18n.t(`activerecord.attributes.legal/attorney.titles.${attorney.title}`),
                                            attorney.title_clarification,
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div>{attorney.location}</div>
                                <div>{attorney.phone}</div>
                            </Col>
                            <Col sm={4}>
                                <div>{attorney.email}</div>
                                <div>
                                    {attorney.linkedin_url && (
                                        <a href={attorney.linkedin_url}>
                                            {I18n.t("activerecord.attributes.legal/attorney.linkedin_url")}
                                        </a>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="overflow-auto" style={{ maxHeight: "70vh" }}>
                            <Col sm={8} className="border-right">
                                <TextField
                                    label={I18n.t("activerecord.attributes.legal/attorney.overview")}
                                    value={attorney.overview}
                                />

                                <NotableRecords
                                    records={attorney.notable_accomplishments}
                                    label={I18n.t("activerecord.attributes.legal/attorney.notable_accomplishments")}
                                />

                                <NotableRecords
                                    records={attorney.notable_cases}
                                    label={I18n.t("activerecord.attributes.legal/attorney.notable_cases")}
                                />

                                <TextField
                                    label={I18n.t("activerecord.attributes.legal/attorney.pro_bono")}
                                    value={attorney.pro_bono}
                                />
                            </Col>
                            <Col sm={4}>
                                <ListValues
                                    values={attorney.practice_areas}
                                    label={I18n.t("activerecord.attributes.legal/attorney.practice_area")}
                                />

                                <LabelWithValue
                                    value={attorney.law_school}
                                    label={I18n.t("activerecord.attributes.legal/attorney.law_school")}
                                />

                                <LabelWithValue
                                    value={attorney.graduation_year?.toString()}
                                    label={I18n.t("activerecord.attributes.legal/attorney.graduation_year")}
                                />

                                <BarDetails values={attorney.bar_details} />

                                <LabelWithValue
                                    value={attorney.timekeeper_id}
                                    label={I18n.t("activerecord.attributes.legal/attorney.timekeeper_id")}
                                />

                                <ListValues
                                    values={attorney.attorney_bar_admissions}
                                    label={I18n.t(
                                        "activerecord.attributes.legal/attorney.attorney_bar_admissions_attributes",
                                    )}
                                />

                                <LabelWithValue
                                    value={attorney.court_admission}
                                    label={I18n.t("activerecord.attributes.legal/attorney.court_admission")}
                                />

                                {attorney.industry_relations.length > 0 && (
                                    <>
                                        <strong>
                                            {I18n.t(
                                                "activerecord.attributes.legal/attorney.industry_relations_attributes",
                                            )}
                                        </strong>

                                        <ul>
                                            {_map(attorney.industry_relations, (industry_relation) => (
                                                <li key={industry_relation.id}>
                                                    {industry_relation.primary ? (
                                                        <strong>{industry_relation.name}</strong>
                                                    ) : (
                                                        industry_relation.name
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}

                                <LabelWithValue
                                    value={attorney.notable_honors}
                                    label={I18n.t("activerecord.attributes.legal/attorney.notable_honors")}
                                />

                                {attorney.attorney_languages.length > 0 && (
                                    <>
                                        <strong>
                                            {I18n.t(
                                                "activerecord.attributes.legal/attorney.attorney_languages_attributes",
                                            )}
                                        </strong>
                                        <br />
                                        {_map(attorney.attorney_languages, (item) => (
                                            <Badge key={item.id} variant="secondary" className="mr-2">
                                                {item.language}
                                            </Badge>
                                        ))}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

AttorneyModal.propTypes = {
    attorneyUid: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AttorneyModal;
